<template>
  <div class="popup-comment-on-moderation">
    <div class="popup-comment-on-moderation__wrapper">
      <p class="popup-comment-on-moderation__title">
        Ваш комментарий будет добавлен после проверки модератором</p>
      <Row justify="center">
        <Button color="main"
                v-on:click.native="close">Хорошо
        </Button>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupCommentOnModeration',
  methods: {
    close() {
      this.$store.commit('CLOSE_POPUP');
    },
  },
};
</script>
